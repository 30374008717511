<template>
  <div>
    <!-- shopMoney商户资金概况 -->
    <en-table-layout tips :tableData="tableData.data" :loading="loading" :toolbar="false">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!-- 请输入商户名称进行搜索-->
          <div class="conditions">
            <el-input
              class="ipt-default"
              style="width: 200px"
              v-model="advancedForm.shop_name"
              placeholder="请输入商户名称进行搜索"
              clearable
            ></el-input>
            <el-button class="btn-default" @click="searchEvent">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="shop_no" label="编号" width="180" />
        <el-table-column label="创建日期" width="180">
          <template
            slot-scope="scope"
          >{{ scope.row.create_date | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column prop="link_name" label="创建人" width="180" />
        <el-table-column prop="shop_name" label="商户名称" width="180" />
        <el-table-column label="待结算金额" width="180">
          <template
            slot-scope="scope"
          >{{ scope.row.unsettle_money?scope.row.unsettle_money:0 | unitPrice("￥")}}</template>
        </el-table-column>
        <el-table-column label="当前账户余额" width="180">
          <template
            slot-scope="scope"
          >{{ (scope.row.unwithdrawal_money?scope.row.unwithdrawal_money:0) + (scope.row.withdrawal_money?scope.row.withdrawal_money:0) | unitPrice("￥")}}</template>
        </el-table-column>
        <el-table-column label="可提现金额" width="180">
          <template
            slot-scope="scope"
          >{{ scope.row.withdrawal_money?scope.row.withdrawal_money:0 | unitPrice("￥")}}</template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="280">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleClickDetail(scope.row,true)">结算明细</el-button>
            <el-button size="mini" type="text" @click="handleClickDetail(scope.row,false)">退款明细</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_Shop from "@/api/shop";
// import { RegExp } from "bms-common/ui-utils";

export default {
  name: "shopMoney",

  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
      },

      /** 列表数据 */
      tableData: "",

      /** 高级搜索数据 */
      advancedForm: {
        shop_name: "", // 根据关键字搜索
      },
    };
  },
  watch: {
    // "addMemberForm.region"(next, prev) {
    //   if (next && next.length) {
    //     this.$refs.addMemberForm.clearValidate("region");
    //   }
    // }
  },
  mounted() {
    this.GET_List();
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_List();
    next();
  },
  methods: {
    /** 查看详情 */
    handleClickDetail(row, type) {
      // type===true->交易流水 else 退款明细
      console.log(row);
      if (type) {
        // 交易流水列表
        this.$router.push({
          name: "结算明细",
          params: { id: row.shop_id },
        });
      } else {
        // 交易退款明细
        this.$router.push({
          name: "交易退款明细",
          params: { id: row.shop_id },
        });
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },

    /** 搜索事件触发 */
    searchEvent() {
      // Object.keys(this.advancedForm).forEach(key => delete this.params[key]);
      this.params.page_no = 1;
      this.params = {
        ...this.params,
        page_no: 1,
        ...this.advancedForm,
      };
      this.GET_List();
    },

    /** 获取列表 */
    GET_List() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Shop.getShopList(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.show-pwd {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}

.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
</style>
